import React from 'react';
import KeyboardMonkey from '../assets/keyboard-monkey.svg';
import KeyboardDracula from '../assets/keyboard-dracula.svg';
import KeyboardSynthWave from '../assets/keyboard-synth-wave.svg';
import KeyboardSolarize from '../assets/keyboard-solarize.svg';
import KeyboardHorizon from '../assets/keyboard-horizon.svg';
import KeyboardTomorrow from '../assets/keyboard-tomorrow.svg';
import { ThemeContext } from '../Theme';
import { useContext } from 'react';

export default function KeyboardIcon() {
  const { themeType, theme } = useContext(ThemeContext);

  const getIconSource = () => {
    switch (themeType) {
      case 'default': {
        if (theme === 'dark') return KeyboardMonkey;
        else if (theme === 'light') return KeyboardSolarize;
        break;
      }
      case 'dracula': {
        return KeyboardDracula;
      }
      case 'synth-wave': {
        return KeyboardSynthWave;
      }
      case 'horizon': {
        return KeyboardHorizon;
      }
      case 'tomorrow': {
        return KeyboardTomorrow;
      }
      default:
        return null;
    }
  };

  return (
    <div className='keyboard-icon'>
      <img
        width={themeType === 'synth-wave' ? 32 : 26}
        src={getIconSource()}
        alt='keyboard-icon'
      />
    </div>
  );
}
