import React from "react";

const JsonArray = ({ setInputJSON }) => {
  const insertJsonArray = (e) => {
    e.preventDefault();
    setInputJSON(
      JSON.stringify(
        [
          {
            id: 1,
            description:
              "This is another example policy which works together with the example consumer and resource server app.",
            subjects: ["foo@bar.com"],
            effect: "allow",
            resources: ["blog:posts:1"],
            actions: ["blog:read"],
          },
        ],
        null,
        2
      )
    );
  };

  return (
    <a className="json-array" href="# /" onClick={(e) => insertJsonArray(e)}>
      {" "}
      Array
    </a>
  );
};
export default JsonArray;
