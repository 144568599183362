import React from 'react';

const SimpleJson = ({ setInputJSON, inputJSON }) => {
  const insertSimpleJson = (e) => {
    e.preventDefault();

    setInputJSON(
      JSON.stringify(
        {
          albumId: 1,
          id: 1,
          title: 'accusamus beatae ad facilis cum similique qui sunt',
          url: 'https://via.placeholder.com/600/92c952',
          thumbnailUrl: 'https://via.placeholder.com/150/92c952',
          flag: true,
        },
        null,
        2
      )
    );
  };

  return (
    <div>
      <a
        href='#/'
        onClick={insertSimpleJson}
        className='simple-json'
      >
        {' '}
        Simple
      </a>
    </div>
  );
};

export default SimpleJson;
