import React from 'react';

export default function KeymapPopover({ code, show, position }) {
  return (
    <>
      {show ? (
        <div
          className={`keymap-popover  ${code === 'I' ? 'checkbox-keymap' : ''}
          ${code === 'C' ? '' : `keymap-${position}`}
                      `}
        >
          <div className={`carrot-sign carrot-${position} `}></div>
          {`[${code}]`}
        </div>
      ) : (
        ''
      )}
    </>
  );
}
