import React from 'react';
import MonkeyFace from '../../assets/monkey-face.svg';

export default function MonkeyType() {
  return (
    <div className='monkey-face'>
      <div className='monkey-eyes'>
        <div id='left-eye' />
        <div id='right-eye' />
      </div>
      <img
        src={MonkeyFace}
        alt='monkey-face'
      />
    </div>
  );
}
