import React, { useContext, useState } from 'react';
import SimpleJson from '../sampleButtons/SimpleJson';
import NestedJson from '../sampleButtons/NestedJson';
import JsonArray from '../sampleButtons/JsonArray';
import ComplexJson from '../sampleButtons/ComplexJson';
import SimpleJsonl from './sampleJsonl/SimpleJsonl';
import NestedJsonl from './sampleJsonl/NestedJsonl';
import JsonlArray from './sampleJsonl/JsonlArray';
import ComplexJsonl from './sampleJsonl/ComplexJsonl';
import { Controlled as CodeMirror } from 'react-codemirror2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardCheck } from '@fortawesome/free-solid-svg-icons';
import isEmptyObject from '../utils/ObjectUtils.js';
import {
  checkJSONType,
  detectDataFormat,
  validateJSON,
  validateJSONL,
} from '../utils/heplers';
import { useKey } from '../hooks/useKey';
import ShortkeyToast from '../Portals/shortkeyToast';
import ShortkeyPopover from './ShortkeyPopover';
import KeymapPopover from './KeyMapPopover';
import KeyboardIconPopover from './KeyboardIconPopover';
import { ThemeContext } from '../Theme';
import KeyboardIcon from './KeyboardIcon';
import CopyIcon from './CopyIcon';

function Json({
  jsonComponentProps: {
    toggleDataType,
    copyTextFromTextArea,
    handleClick,
    realTimeValidation,
    spaces,
    setSpaces,
    errorMesssage,
    setErrorMessage,
    inputJSON,
    setInputJSON,
    jsonData,
    setTableFlag,
    showDataType,
    setSuccessMessage,
    successMessage,
    setAutoValidate,
    autoValidate,
    isCopied,
    setSpaceErrorMessage,
    spaceErrorMessage,
    inputFormat,
    setInputFormat,
  },
}) {
  const { theme, themeType } = useContext(ThemeContext);
  const [shortKeyObject, setShortKeyObject] = useState({
    minifyKey: false,
    reformatKey: false,
    validateKey: false,
    toggleKey: false,
    copyKey: false,
  });
  const [showMinifyKeyPopover, setShowMinifyKeyPopover] = useState(false);
  const [showReformatKeyPopover, setShowReformatKeyPopover] = useState(false);
  const [showValidateKeyPopover, setShowValidateKeyPopover] = useState(false);
  const [showToggleKeyPopover, setShowToggleKeyPopover] = useState(false);
  const [showKeyBoardIconPopover, setShowKeyBoardIconPopover] = useState(false);
  const [showKeyMap, setShowKeyMap] = useState(false);
  const setShowKeyMapFlagHandler = () => {
    let ele = document.getElementById('keymap-backdrop');
    if (showKeyMap) {
      ele.classList.remove('visible');
    } else {
      ele.classList.add('visible');
    }
    setShowKeyMap((prevState) => !prevState);
  };

  const handleReformat = (e) => {
    if (inputFormat !== 'JSONL') {
      const numberOfSpaces = parseInt(spaces);
      try {
        !numberOfSpaces ? (
          <>
            {
              (setSpaces(2),
              setInputJSON(JSON?.stringify(JSON?.parse(inputJSON), null, 2)))
            }
          </>
        ) : numberOfSpaces && numberOfSpaces > 0 && numberOfSpaces < 10 ? (
          setInputJSON(
            JSON?.stringify(JSON?.parse(inputJSON), null, numberOfSpaces)
          )
        ) : (
          setSpaceErrorMessage('1-9')
        );
      } catch ({ message }) {
        message
          ? setErrorMessage(`JSON is invalid: ${message}`)
          : setErrorMessage('');
      }
    } else {
      let formattedJSONL = ``;
      const lines = inputJSON?.trim()?.split('\n');
      for (let i = 0; i < lines?.length; i++) {
        try {
          formattedJSONL += `${JSON?.stringify(JSON?.parse(lines?.[i]), null)}${
            i === lines?.length - 1 ? '' : '\n'
          }`;
        } catch (error) {
          return null;
        }
      }
      setInputJSON(formattedJSONL);
    }
  };

  const onValidate = () => {
    const { valid: isValidJSON, message: validationJSONMessage } =
      validateJSON(inputJSON);
    const { valid: isValidJSONL, message: validationJSONLMessage } =
      validateJSONL(inputJSON);
    if (isValidJSON) {
      setErrorMessage('');
      setSuccessMessage(validationJSONMessage);
      setInputFormat('JSON');
    } else if (isValidJSONL) {
      setErrorMessage('');
      setSuccessMessage(validationJSONLMessage);
      setInputFormat('JSONL');
    } else {
      setErrorMessage(validationJSONLMessage);
      setSuccessMessage('');
      setInputFormat(null);
    }
  };

  const minfiyHandler = () => {
    try {
      setInputJSON(JSON?.stringify(JSON?.parse(inputJSON), null, 0));
    } catch ({ message }) {
      setErrorMessage(message ? `JSON is invalid: ${message}` : '');
    }
  };

  // These hooks will only handle MACOS specified key operations.

  useKey('m', () => {
    // only minify JSON when it has some value and reformated
    if (inputJSON && inputJSON?.split('\n')?.length > 1) {
      minfiyHandler();
      setShortKeyObject({ ...shortKeyObject, minifyKey: 'minified' });
    }
  });
  useKey('b', () => {
    if (inputJSON) {
      onValidate();
      setShortKeyObject({ ...shortKeyObject, validateKey: 'validated' });
    }
  });
  useKey('.', (e) => {
    // only reformat JSON when it has some value and minified
    if (inputJSON && inputJSON?.split('\n')?.length <= 1) {
      handleReformat(e);
      setShortKeyObject({ ...shortKeyObject, reformatKey: 'reformat' });
    }
  });

  useKey('i', () => {
    if (!autoValidate) {
      setAutoValidate(true);
      realTimeValidation(inputJSON);
    } else setAutoValidate(false);
    setShortKeyObject({ ...shortKeyObject, toggleKey: 'toggle' });
  });

  useKey('c', () => {
    if (inputJSON) {
      navigator?.clipboard?.writeText(inputJSON);
      setShortKeyObject({ ...shortKeyObject, copyKey: 'copied' });
    }
  });

  const getShortKeyId = (key) => {
    switch (key) {
      case 'minifyKey':
        return 'shortkey-toast-minify';
      case 'reformatKey':
        return 'shortkey-toast-reformat';
      case 'validateKey':
        return 'shortkey-toast-validate';
      case 'toggleKey':
        return 'shortkey-toast-toggle-validate';
      case 'copyKey':
        return 'shortkey-copy-json';
      default:
        return '';
    }
  };

  return (
    <div className='container'>
      <div className='container'>
        <div className='text-area-container d-flex'>
          <div className='ml-auto'>
            <div className='empty-container-left'>&nbsp;</div>
          </div>
          <div className='left-btn-container d-flex fd-column'>
            <div className='example-container'>
              <p className='example-text'>JSON Examples</p>
              <SimpleJson
                setInputJSON={(string) => {
                  setInputJSON(string);
                  setInputFormat('JSON');
                }}
                inputJSON={inputJSON}
              />
              <JsonArray
                className='json-array'
                setInputJSON={(string) => {
                  setInputJSON(string);
                  setInputFormat('JSON');
                }}
              />
              <NestedJson
                className='nested-json'
                setInputJSON={(string) => {
                  setInputJSON(string);
                  setInputFormat('JSON');
                }}
                inputJSON={inputJSON}
              />
              <ComplexJson
                className='complex-json'
                setInputJSON={(string) => {
                  setInputJSON(string);
                  setInputFormat('JSON');
                }}
              />
            </div>

            <div className='example-container'>
              <p className='example-text'>JSONL Examples</p>
              <SimpleJsonl
                setInputJSON={(string) => {
                  setInputJSON(string);
                  setInputFormat('JSONL');
                }}
                inputJSON={inputJSON}
              />
              <JsonlArray
                setInputJSON={(string) => {
                  setInputJSON(string);
                  setInputFormat('JSONL');
                }}
                className='json-array'
              />
              <NestedJsonl
                className='nested-json'
                setInputJSON={(string) => {
                  setInputJSON(string);
                  setInputFormat('JSONL');
                }}
                inputJSON={inputJSON}
              />
              <ComplexJsonl
                setInputJSON={(string) => {
                  setInputJSON(string);
                  setInputFormat('JSONL');
                }}
                className='complex-json'
              />
            </div>

            <div>
              <div>
                <div
                  className='data-type-container'
                  hidden={isEmptyObject(jsonData)}
                >
                  <div className='data-type-text-container'>
                    <p className='data-type-text'>data type</p>
                  </div>
                  <label className='switch p-relative d-flex'>
                    <input
                      type='checkbox'
                      className='data-slider'
                      onChange={toggleDataType}
                      checked={showDataType}
                    />
                    <span className='slider custom ' />
                  </label>
                </div>
              </div>
              <button
                onClick={() => {
                  setTableFlag((prevState) => !prevState);
                }}
                hidden={isEmptyObject(jsonData)}
                className={isEmptyObject(jsonData) ? '' : 'button-3d'}
              >
                Transpose
              </button>
            </div>
          </div>
          <div className='p-relative'>
            <CodeMirror
              className='custom-editor'
              value={inputJSON}
              options={{
                mode: {
                  name: 'javascript',
                  json: detectDataFormat(inputJSON) === 'JSON',
                  statementIndent: spaces,
                },
                indentUnit: spaces,
                lint: true,
                placeholder: 'Start typing or drop a JSON/JSONL file...',
                theme: theme === 'dark' ? 'monkeytype' : 'solarized-light',
                lineNumbers: true,
                tabSize: spaces,
              }}
              onBeforeChange={(_, __, value) => {
                setInputFormat(checkJSONType(value));
                setInputJSON(value);
                if (autoValidate) {
                  realTimeValidation(value);
                } else {
                  setErrorMessage('');
                  setSuccessMessage('');
                }
              }}
            />
            <div className='copy-button-wrapper'>
              <div
                className='copy-button'
                onClick={copyTextFromTextArea}
                data-for='copy-tooltip'
              >
                {isCopied ? (
                  <FontAwesomeIcon
                    className='copy-button'
                    icon={faClipboardCheck}
                  />
                ) : (
                  <CopyIcon />
                )}
              </div>

              <KeymapPopover
                code='C'
                show={showKeyMap}
                position='bottom'
              />
            </div>
            <div
              className={`show-keymap-button-wrapper ${
                themeType === 'synth-wave' ? 'synth-wave-keyboard-icon' : ''
              }`}
            >
              <div
                className='show-keymap-button'
                onClick={setShowKeyMapFlagHandler}
                onMouseOver={() => setShowKeyBoardIconPopover(true)}
                onMouseLeave={() => setShowKeyBoardIconPopover(false)}
              >
                <KeyboardIcon />
              </div>
              <KeyboardIconPopover show={showKeyBoardIconPopover} />
            </div>
          </div>
          <div className='right-btn-container d-flex fd-column jc-between'>
            <div className='d-<flex fd-column'>
              <div className='d-flex ai-center p-relative'>
                {/* Reformat Button */}
                <button
                  onMouseEnter={() => setShowReformatKeyPopover(true)}
                  onMouseOver={() => setShowReformatKeyPopover(true)}
                  onMouseLeave={() => setShowReformatKeyPopover(false)}
                  className='button-3d-sm'
                  onClick={handleReformat}
                >
                  Reformat
                </button>
                <ShortkeyPopover
                  code='Reformat'
                  shortkey='.'
                  show={showReformatKeyPopover}
                />
                <KeymapPopover
                  code='B'
                  show={showKeyMap}
                  position='top'
                />
                {inputFormat !== 'JSONL' ? (
                  <div className='reformat-container'>
                    <input
                      className='reformat-input'
                      value={spaces}
                      type='number'
                      min='1'
                      max='9'
                      maxLength='1'
                      onChange={(e) => {
                        const value = parseInt(e?.target?.value);
                        if (!Number?.isNaN(value))
                          if (value < 1) setSpaces(1);
                          else if (value > 9) setSpaces(9);
                          else setSpaces(value);
                        if (!e?.target?.value) setSpaces();
                      }}
                    />
                    <label className='reformat-text'>tab size</label>
                    <p className='space-error-message'>{spaceErrorMessage}</p>
                  </div>
                ) : null}
              </div>
              {/* Minify Button */}
              <div className='p-relative'>
                <button
                  onMouseEnter={() => setShowMinifyKeyPopover(true)}
                  onMouseOver={() => setShowMinifyKeyPopover(true)}
                  onMouseLeave={() => setShowMinifyKeyPopover(false)}
                  className='button-3d-sm'
                  onClick={inputFormat === 'JSONL' ? () => {} : minfiyHandler}
                >
                  Minify
                </button>
                <ShortkeyPopover
                  code='Minify'
                  shortkey='m'
                  show={showMinifyKeyPopover}
                />
                <KeymapPopover
                  code='M'
                  show={showKeyMap}
                  position='right'
                />
              </div>
              {/* Validate Button */}
              <div className='d-flex ai-center p-relative'>
                <button
                  onMouseEnter={() => setShowValidateKeyPopover(true)}
                  onMouseOver={() => setShowValidateKeyPopover(true)}
                  onMouseLeave={() => setShowValidateKeyPopover(false)}
                  className='button-3d-sm'
                  onClick={onValidate}
                >
                  Validate
                </button>
                <ShortkeyPopover
                  code='Validate'
                  shortkey='b'
                  show={showValidateKeyPopover}
                />
                <KeymapPopover
                  code='.'
                  show={showKeyMap}
                  position='bottom'
                />
                {/* Validate Checkbox */}
                <div className='auto-container'>
                  <label
                    className='auto-label'
                    onMouseEnter={() => setShowToggleKeyPopover(true)}
                    onMouseOver={() => setShowToggleKeyPopover(true)}
                    onMouseLeave={() => setShowToggleKeyPopover(false)}
                  >
                    <span className='auto-text'>auto</span>

                    <input
                      type='checkbox'
                      className='auto-validate'
                      onClick={(e) => {
                        if (e?.target?.checked) {
                          setAutoValidate(true);
                          realTimeValidation(inputJSON);
                        } else {
                          setAutoValidate(false);
                        }
                      }}
                      checked={autoValidate}
                    />
                    <span className='checkmark' />
                  </label>
                  <ShortkeyPopover
                    code='Toggle validate'
                    shortkey='i'
                    show={showToggleKeyPopover}
                  />
                  <KeymapPopover
                    code='I'
                    show={showKeyMap}
                    position='bottom'
                  />
                </div>
              </div>
            </div>

            <div className=''>
              <button
                onClick={handleClick}
                className='button-3d generate'
              >
                Generate
              </button>
            </div>
          </div>
          <div className='mr-auto'>
            <div className='empty-container-right'>&nbsp;</div>
          </div>
        </div>
      </div>
      <div className='error-messsage-container'>
        {errorMesssage || successMessage ? '' : <p>&nbsp;</p>}
        <p className='error-message'>{errorMesssage}</p>
        <p className='success-message'>{successMessage}</p>
      </div>
      <div className='toggle-data-type' />

      {Object.keys(shortKeyObject)?.map((key) => (
        <ShortkeyToast
          key={key}
          text={shortKeyObject[key]}
          onClose={() => setShortKeyObject({ ...shortKeyObject, [key]: '' })}
          id={getShortKeyId(key)}
        />
      ))}
    </div>
  );
}

export default Json;
