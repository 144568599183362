import React from 'react';

const NestedJson = ({ setInputJSON }) => {
  const insertNestedJson = (e) => {
    e.preventDefault();
    setInputJSON(`{"name": "John", "age": 25, "friends": [{"name": "Emily", "age": 30}, {"name": "Mike", "age": 27}]}
{"name": "Emily", "age": 30, "friends": [{"name": "John", "age": 25}, {"name": "Sarah", "age": 28}]}`);
  };

  return (
    <div>
      <a
        className='nested-json'
        href='#/'
        onClick={insertNestedJson}
      >
        {' '}
        Nested
      </a>
    </div>
  );
};
export default NestedJson;
