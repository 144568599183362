import CollapseObjectTable from '../components/CollapseObjectTable';
import CollapseArrayTable from '../components/CollapseArrayTable';

const makeTableFromObject = (jsonData, showDataType) => {
  return (
    <CollapseObjectTable
      data={jsonData}
      makeTableFromArray={makeTableFromArray}
      makeTableFromObject={makeTableFromObject}
      showDataType={showDataType}
    />
  );
};
const makeTableFromArray = (jsonData, showDataType) => {
  return (
    <CollapseArrayTable
      data={jsonData}
      makeTableFromArray={makeTableFromArray}
      makeTableFromObject={makeTableFromObject}
      showDataType={showDataType}
    />
  );
};

export { makeTableFromObject, makeTableFromArray };
