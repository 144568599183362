import React, { useState } from 'react';
import FontAwesomePlus from './FontAwesomePlus';
import FontAwesomeMinus from './FontAwesomeMinus';
import isEmptyObject from '../utils/ObjectUtils.js';
import checkValueTypeVerticalTable from '../utils/CheckValueTypeVerticalTable';
const CollapseVerticalArrayTable = ({ data, showDataType }) => {
  const [collapseTableFlag, setCollapseTableFlag] = useState(true);
  const handleClick = () => setCollapseTableFlag(!collapseTableFlag);
  let arrKey;
  if (
    typeof data?.[0] === 'object' ??
    !Array.isArray(Object.values(data?.[0]))
  ) {
    arrKey = Object?.keys(data?.[0]);
  }
  return (
    <div>
      <div className='button-div'>
        <button className='fontawesome-button' onClick={handleClick}>
          {collapseTableFlag ? <FontAwesomeMinus /> : <FontAwesomePlus />}
        </button>
      </div>
      {isEmptyObject(data) ? (
        <div className='empty-object'>empty object</div>
      ) : (
        <table
          border='1'
          className={collapseTableFlag ? '' : 'display-table-content'}
        >
          <thead>
            <tr>
              {data &&
                data?.map((obj, index) =>
                  index === 0
                    ? arrKey?.map((key, i) => (
                        <th key={index}>
                          {key}
                          {Array.isArray(data?.[obj]) ? (
                            data?.[obj]?.length
                          ) : (
                            <>
                              {' '}
                              <br />
                              <div className='data-type'>
                                {showDataType ? ` ${typeof obj?.[key]}` : ''}
                              </div>{' '}
                            </>
                          )}
                        </th>
                      ))
                    : null
                )}
            </tr>
          </thead>
          <tbody>
            {data &&
              data?.map((dataValue, index) => (
                <tr key={index}>
                  {(dataValue && typeof dataValue === 'string') ||
                  typeof dataValue === Number ? (
                    <>
                      <td>{dataValue}</td>
                      <br />
                    </>
                  ) : (
                    Object?.keys(dataValue)?.map((item, i) => (
                      <td key={i}>
                        {checkValueTypeVerticalTable(
                          dataValue?.[item],
                          showDataType
                        )}
                      </td>
                    ))
                  )}
                </tr>
              ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default CollapseVerticalArrayTable;
