import React from "react";

const ComplexJson = ({ setInputJSON }) => {
  const insertComplexJson = (e) => {
    e.preventDefault();
    setInputJSON(
      JSON.stringify(
        {
          meta: {
            view: {
              id: "7vy4-ats6",
              name: "2006 - 2012 Math Test Results - Borough - SWD",
              assetType: "dataset",
              attribution: "Department of Education (DOE)",
              attributionLink:
                "http://schools.nyc.gov/NR/rdonlyres/9EE3AC39-71D5-4619-9F99-A850842F68DE/0/BoroughMathResults20062012Public.xlsx",
              averageRating: 0,
              category: "Education",
              createdAt: 1361414606,
              description:
                "Latest available data and trends in the state assessment results of math for grades 3 through 8. Data are disaggregated by borough and disability status.",
              displayType: "table",
              downloadCount: 1907,
              hideFromCatalog: false,
              hideFromDataJson: false,
              indexUpdatedAt: 1536596765,
              newBackend: true,
              numberOfComments: 0,
              approvals: [
                {
                  reviewedAt: 1361446012,
                  reviewedAutomatically: true,
                  state: "approved",
                  submissionId: 1064325,
                  submissionObject: "public_audience_request",
                  submissionOutcome: "change_audience",
                  submittedAt: 1361446012,
                  workflowId: 2285,
                  submissionDetails: {
                    permissionType: "READ",
                  },
                  submissionOutcomeApplication: {
                    failureCount: 0,
                    status: "success",
                  },
                },
              ],
              owner: {
                id: "5fuc-pqz2",
                displayName: "NYC OpenData",
                profileImageUrlLarge:
                  "/api/users/5fuc-pqz2/profile_images/LARGE",
                profileImageUrlMedium:
                  "/api/users/5fuc-pqz2/profile_images/THUMB",
                profileImageUrlSmall:
                  "/api/users/5fuc-pqz2/profile_images/TINY",
                screenName: "NYC OpenData",
                type: "interactive",
                flags: ["acceptedEula", "mayBeStoriesCoOwner"],
              },
              query: {},
              rights: ["read"],
              tableAuthor: {
                id: "ya7g-926w",
                displayName: "Aakash Dalwani",
                screenName: "Aakash Dalwani",
                type: "interactive",
              },
              tags: [
                "math test result",
                "grade",
                "borough",
                "swd",
                "lifelong learning",
              ],
              flags: [
                "default",
                "ownerMayBeContacted",
                "restorable",
                "restorePossibleForType",
              ],
            },
          },
        },
        null,
        2
      )
    );
  };

  return (
    <div>
      <a className="complex-json" href="#/" onClick={insertComplexJson}>
        {" "}
        Complex
      </a>
    </div>
  );
};

export default ComplexJson;
