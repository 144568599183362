import React, { useState } from 'react';
import FontAwesomePlus from './FontAwesomePlus';
import FontAwesomeMinus from './FontAwesomeMinus';
import isEmptyObject from '../utils/ObjectUtils.js';
import checkValueTypeVerticalTable from '../utils/CheckValueTypeVerticalTable';
const CollapseVerticalObjectTable = ({ data, showDataType }) => {
  const [collapseTableFlag, setCollapseTableFlag] = useState(true);
  const handleClick = () => setCollapseTableFlag(!collapseTableFlag);

  return (
    <div>
      {isEmptyObject(data) ? (
        <div className='empty-object'>empty object</div>
      ) : (
        <>
          <div className='button-div'>
            <button className='fontawesome-button' onClick={handleClick}>
              {collapseTableFlag ? <FontAwesomeMinus /> : <FontAwesomePlus />}
            </button>
          </div>
          <table
            border='1'
            className={collapseTableFlag ? '' : 'display-table-content'}
          >
            <thead>
              <tr>
                {data &&
                  Object?.keys(data)?.map((val, index) => {
                    return (
                      <>
                        <th key={index}>
                          {val}
                          {Array?.isArray(data?.[val]) ? (
                            `  (${data?.[val]?.length})`
                          ) : (
                            <div key={index} className='data-type'>
                              {showDataType ? `${typeof data?.[val]}` : ''}
                            </div>
                          )}{' '}
                        </th>
                      </>
                    );
                  })}
              </tr>
            </thead>
            <tbody>
              <tr>
                {data &&
                  Object?.keys(data)?.map((entity, index) => (
                    <td key={index}>
                      {checkValueTypeVerticalTable(
                        data?.[entity],
                        showDataType
                      )}
                    </td>
                  ))}{' '}
              </tr>
            </tbody>
          </table>
        </>
      )}
    </div>
  );
};

export default CollapseVerticalObjectTable;
