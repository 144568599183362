import React, { useState } from 'react';
import TextArea from './components/TextArea';
import Table from './components/Table';
import HorizontalView from './components/HorizontalView';
import isEmptyObject from './utils/ObjectUtils.js';
import './App.css';

const App = () => {
  const [inputJSON, setInputJSON] = useState('');
  const [jsonData, setJsonData] = useState(null);
  const [tableFlag, setTableFlag] = useState(true);
  const [showDataType, setShowDataType] = useState(true);

  return (
    <div>
      <TextArea
        inputJSON={inputJSON}
        setInputJSON={setInputJSON}
        jsonData={jsonData}
        setJsonData={setJsonData}
        setTableFlag={setTableFlag}
        tableFlag={tableFlag}
        showDataType={showDataType}
        setShowDataType={setShowDataType}
      />
      {jsonData && isEmptyObject(jsonData) ? (
        <p className='nothing-to-display'> Nothing to Display </p>
      ) : jsonData && !isEmptyObject(jsonData) && JSON?.stringify(jsonData) ? (
        tableFlag === true ? (
          <div className='horizontal-container'>
            <HorizontalView
              jsonData={jsonData}
              showDataType={showDataType}
            />
          </div>
        ) : (
          <div className='vertical-container'>
            <Table
              jsonData={jsonData}
              showDataType={showDataType}
            />
          </div>
        )
      ) : null}
    </div>
  );
};

export default App;
