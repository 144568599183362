import CollapseVerticalArrayTable from '../components/CollapseVerticalArrayTable';
import CollapseVerticalObjectTable from '../components/CollapseVerticalObjectTable';
const makeTableFromArray = (dataItem, showDataType) => {
  return (
    <CollapseVerticalArrayTable
      data={dataItem}
      makeTableFromArray={makeTableFromArray}
      makeTableFromObject={makeTableFromObject}
      showDataType={showDataType}
    />
  );
};
const makeTableFromObject = (dataItem, showDataType) => {
  return (
    <CollapseVerticalObjectTable
      data={dataItem}
      makeTableFromArray={makeTableFromArray}
      makeTableFromObject={makeTableFromObject}
      showDataType={showDataType}
    />
  );
};
export { makeTableFromArray, makeTableFromObject };
