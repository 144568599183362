import React from 'react';

const SimpleJson = ({ setInputJSON, inputJSON }) => {
  const insertSimpleJson = (e) => {
    e.preventDefault();

    setInputJSON(`{"name": "John", "age": 25, "city": "New York", "occupation": "Engineer"}
{"name": "Emily", "age": 30, "city": "San Francisco", "occupation": "Designer"}
{"name": "Michael", "age": 35, "city": "Chicago", "occupation": "Teacher"}`);
  };

  return (
    <div>
      <a
        href='#/'
        onClick={insertSimpleJson}
        className='simple-json'
      >
        {' '}
        Simple
      </a>
    </div>
  );
};

export default SimpleJson;
