import React from 'react';

export default function CancelIcon() {
  return (
    <svg
      width='14'
      height='14'
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        className='cancel-icon'
        d='M0.959641 11.6264C0.569117 12.017 0.569117 12.6501 0.959641 13.0406C1.35017 13.4312 1.98333 13.4312 2.37385 13.0406L7.00008 8.41442L11.6263 13.0406C12.0168 13.4312 12.65 13.4312 13.0405 13.0406C13.431 12.6501 13.431 12.017 13.0405 11.6264L8.41429 7.00021L13.0405 2.37398C13.431 1.98346 13.431 1.35029 13.0405 0.959767C12.65 0.569243 12.0168 0.569243 11.6263 0.959767L7.00008 5.58599L2.37385 0.959769C1.98333 0.569244 1.35017 0.569244 0.959641 0.959769C0.569117 1.35029 0.569117 1.98346 0.959641 2.37398L5.58587 7.00021L0.959641 11.6264Z'
      />
    </svg>
  );
}
