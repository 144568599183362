import React from 'react';
import Moon from '../../assets/moon.svg';
import Cloud from '../../assets/cloud.svg';

export default function Dracula() {
  return (
    <div className='dracula-svg'>
      <div className='moon'>
        <img
          src={Moon}
          alt='moon-img'
        />
      </div>
      <div className='cloud'>
        <img
          src={Cloud}
          alt='cloud-img'
        />
      </div>
    </div>
  );
}
