export const detectDataFormat = (data) => {
  try {
    JSON.parse(data);
    return 'JSON';
  } catch {
    const lines = data?.trim()?.split('\n');
    for (let i = 0; i < lines?.length; i++) {
      try {
        JSON.parse(lines?.[i]);
      } catch (error) {
        return null;
      }
    }
    return 'JSONL';
  }
};

export const validateJSON = (input) => {
  try {
    JSON.parse(input);
    return { valid: true, message: 'JSON is valid!' };
  } catch ({ message }) {
    return { valid: false, message: message || '' };
  }
};
export const validateJSONL = (input) => {
  const lines = input?.trim()?.split('\n');
  for (let i = 0; i < lines?.length; i++) {
    try {
      JSON.parse(lines?.[i]);
    } catch ({ message }) {
      return { valid: false, message: message || '' };
    }
  }
  return { valid: true, message: 'JSONL is valid!' };
};

export const checkJSONType = (input) => {
  try {
    JSON.parse(input);
    return 'JSON';
  } catch ({ message }) {
    const lines = input?.trim()?.split('\n');
    if (input && lines?.length <= 2 && lines?.length > 0) return 'JSONL';
    try {
      JSON.parse(lines?.[0]);
      return 'JSONL';
    } catch ({ message }) {
      return message;
    }
  }
};

export const makeFirstLetterCapital = (str) =>
  str?.charAt(0)?.toUpperCase() + str?.split('-')?.join('')?.slice(1);
