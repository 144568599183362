import React from 'react';
import {
  makeTableFromArray,
  makeTableFromObject,
} from '../utils/MakeVerticalTable';
import './Table.css';

const Table = ({ jsonData, showDataType }) => {
  return (
    <>
      {Array?.isArray(jsonData)
        ? makeTableFromArray(jsonData, showDataType)
        : makeTableFromObject(jsonData, showDataType)}
    </>
  );
};

export default Table;
