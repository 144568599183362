import { useState } from 'react';
import { ThemeContext } from '.';

export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState('light');
  const [themeType, setThemeType] = useState('default');
  const toggleTheme = () => {
    if (theme === 'dark') {
      document.body.classList.add('light-mode');
    } else {
      document.body.classList.remove('light-mode');
    }
    document.body.classList.remove(themeType);
    document.body.classList.remove('preload');
    setTheme(theme === 'light' ? 'dark' : 'light');
    setThemeType('default');
  };

  const setThemeTypeHandler = (type) => {
    setThemeType((prevState) => {
      document.body.classList.remove(prevState);
      document.body.classList.add(type);
      return type;
    });
  };

  return (
    <ThemeContext.Provider
      value={{ theme, toggleTheme, themeType, setThemeTypeHandler }}
    >
      {children}
    </ThemeContext.Provider>
  );
};
