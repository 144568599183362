import React from 'react';

const JsonArray = ({ setInputJSON }) => {
  const insertJsonArray = (e) => {
    e.preventDefault();
    setInputJSON(`{"name": "John", "age": 25, "grades": [90, 85, 95], "subjects": ["Math", "Science", "English"], "friends": ["Emily", "Mike"]}
{"name": "Emily", "age": 30, "grades": [95, 92, 88], "subjects": ["History", "Geography", "Art"], "friends": ["John", "Sarah"]}`);
  };

  return (
    <a
      className='json-array'
      href='# /'
      onClick={(e) => insertJsonArray(e)}
    >
      {' '}
      Array
    </a>
  );
};
export default JsonArray;
