import React, { useState } from 'react';
import FontAwesomePlus from './FontAwesomePlus';
import FontAwesomeMinus from './FontAwesomeMinus';
import isEmptyObject from '../utils/ObjectUtils.js';
import checkValueTypeHorizontalView from '../utils/CheckValueTypeHorizontalView';
const CollapseObjectTable = ({ data, showDataType }) => {
  const [collapseTableFlag, setCollapseTableFlag] = useState(true);
  const handleClick = () => setCollapseTableFlag(!collapseTableFlag);

  return (
    <div>
      {isEmptyObject(data) ? (
        <div className='empty-object'>empty object</div>
      ) : (
        <>
          <div className='button-div'>
            <button className='fontawesome-button' onClick={handleClick}>
              {collapseTableFlag ? <FontAwesomeMinus /> : <FontAwesomePlus />}
            </button>
          </div>

          <table
            border='1'
            className={collapseTableFlag ? '' : 'display-table-content'}
          >
            <tbody>
              {data &&
                Object?.keys(data)?.map((key, index) => (
                  <tr key={index}>
                    <td className='keys'>
                      {key}
                      {Array?.isArray(data?.[key]) ? (
                        ` (${data?.[key]?.length})`
                      ) : (
                        <>
                          {' '}
                          <br />
                          <div className='data-type'>
                            {showDataType ? typeof data?.[key] : ''}
                          </div>
                        </>
                      )}{' '}
                    </td>
                    <td>
                      {checkValueTypeHorizontalView(data?.[key], showDataType)}{' '}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </>
      )}
    </div>
  );
};

export default CollapseObjectTable;
