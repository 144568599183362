import React from 'react';

const ComplexJson = ({ setInputJSON }) => {
  const insertComplexJson = (e) => {
    e.preventDefault();
    setInputJSON(`{"name": "John", "age": 25, "address": {"street": "123 Main St", "city": "New York"}, "hobbies": ["reading", "painting"]}
{"name": "Emily", "age": 30, "address": {"street": "456 Elm St", "city": "San Francisco"}, "hobbies": ["traveling", "photography"]}`);
  };

  return (
    <div>
      <a
        className='complex-json'
        href='#/'
        onClick={insertComplexJson}
      >
        Complex
      </a>
    </div>
  );
};

export default ComplexJson;
