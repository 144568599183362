import React from 'react';

export default function KeyboardIconPopover({ show }) {
  return (
    <div>
      {show ? (
        <div className='keyboard-icon-popover'>
          <div className='keyboard-icon-carrot' />
          <span>{'Use Ctrl+[key] for shortcut commands'}</span>
        </div>
      ) : null}
    </div>
  );
}
