import React, { useState } from 'react';
import CopyMonkey from '../assets/copy-monkey.svg';
import CopyDracula from '../assets/copy-dracula.svg';
import CopySynthWave from '../assets/copy-synth-wave.svg';
import CopySolarize from '../assets/copy-solarize.svg';
import CopyHorizon from '../assets/copy-horizon.svg';
import CopyTomorrow from '../assets/copy-tomorrow.svg';
import { ThemeContext } from '../Theme';
import { useContext } from 'react';

export default function CopyIcon() {
  const { theme, themeType } = useContext(ThemeContext);
  const [showCopyIconPopover, setShowCopyIconPopover] = useState(false);
  const getIconSource = () => {
    switch (themeType) {
      case 'default': {
        if (theme === 'dark') return CopyMonkey;
        else if (theme === 'light') return CopySolarize;
        break;
      }
      case 'dracula': {
        return CopyDracula;
      }
      case 'synth-wave': {
        return CopySynthWave;
      }
      case 'horizon': {
        return CopyHorizon;
      }
      case 'tomorrow': {
        return CopyTomorrow;
      }
      default:
        return null;
    }
  };
  return (
    <div
      className='copy-icon'
      onMouseOver={() => setShowCopyIconPopover(true)}
      onMouseLeave={() => setShowCopyIconPopover(false)}
    >
      <img
        src={getIconSource()}
        alt='copy-icon'
      />
      {showCopyIconPopover ? (
        <div className='copy-icon-popover'>
          Copy to clipboard
          <span className='copy-icon-carrot' />
        </div>
      ) : null}
    </div>
  );
}
