import React from 'react';
import BigStar from '../../assets/bg-star.svg';
import MediumStar from '../../assets/md-star.svg';
import SmallStar from '../../assets/sm-star.svg';

export default function SynthWave() {
  return (
    <div className='synth-wave-svg'>
      <div className='bg-star'>
        <img
          src={BigStar}
          alt='bg-star'
        />
      </div>
      <div className='md-star'>
        <img
          src={MediumStar}
          alt='md-star'
        />
      </div>
      <div className='sm-star'>
        <img
          src={SmallStar}
          alt='sm-star'
        />
      </div>
    </div>
  );
}
