import React from 'react';
import Star from '../../assets/star.svg';
import ShootingStar from '../../assets/shooting-star.svg';

export default function Tomorrow() {
  return (
    <div className='tomorrow-svg'>
      <div className='star'>
        <img
          src={Star}
          alt='star-svg'
        />
      </div>
      <div className='shooting-star'>
        <img
          src={ShootingStar}
          alt='shooting-star-svg'
        />
      </div>
    </div>
  );
}
