import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

const FontAwesomePlus = () => {
  return (
    <div>
      <FontAwesomeIcon className='plus-button' icon={faPlus} />
    </div>
  );
};

export default FontAwesomePlus;
