import { makeTableFromArray, makeTableFromObject } from './MakeHorizontalTable';
const checkValueTypeHorizontalView = (value, showDataType) => {
  return typeof value === 'object'
    ? makeTableFromObject(value, showDataType)
    : Array?.isArray(value)
    ? makeTableFromArray(value, showDataType)
    : typeof value === 'boolean'
    ? value.toString()
    : value;
};
export default checkValueTypeHorizontalView;
