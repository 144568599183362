import React from 'react';
import {
  makeTableFromArray,
  makeTableFromObject,
} from '../utils/MakeHorizontalTable';

const HorizontalView = ({ jsonData, showDataType }) => {
  return (
    <>
      {Array?.isArray(jsonData)
        ? makeTableFromArray(jsonData, showDataType)
        : makeTableFromObject(jsonData, showDataType)}
    </>
  );
};

export default HorizontalView;
