import React from 'react';
import SunFog from '../../assets/sun-fog.svg';
import SunShine1 from '../../assets/sun-shine-1.svg';
import SunShine2 from '../../assets/sun-shine-2.svg';
import SunShine3 from '../../assets/sun-shine-3.svg';

export default function Horizon() {
  return (
    <div className='horizon-svg'>
      <div className='sun-fog'>
        <img
          src={SunFog}
          alt='sun-fog'
        />
      </div>
      <div className='sun-shine-1'>
        <img
          src={SunShine1}
          alt='sun-shine-1'
        />
      </div>
      <div className='sun-shine-2'>
        <img
          src={SunShine2}
          alt='sun-shine-2'
        />
      </div>
      <div className='sun-shine-3'>
        <img
          src={SunShine3}
          alt='sun-shine-3'
        />
      </div>
    </div>
  );
}
