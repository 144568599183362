import React, { useContext } from 'react';
import ThemeButton from './ThemeButton';
import { ThemeContext } from '../Theme';
import { makeFirstLetterCapital } from '../utils/heplers';

export default function ThemeSwitcher() {
  const { theme, themeType, setThemeTypeHandler, toggleTheme } =
    useContext(ThemeContext);
  const getThemeName = () =>
    `${
      themeType === 'default'
        ? theme === 'dark'
          ? 'Monkeytype'
          : 'Solarize'
        : makeFirstLetterCapital(themeType)
    } ${makeFirstLetterCapital(theme)}`;

  return (
    <div>
      <div className='switch theme-button'>
        <label>
          <input
            type='checkbox'
            className='theme-slider'
            checked={theme === 'dark'}
            onChange={toggleTheme}
          />
          <span className='slider custom' />
        </label>
        <label className='theme-label'>
          <strong>{'Theme - '}</strong>
          {getThemeName()}
        </label>
        <div class='theme-btn-list'>
          {theme === 'dark' ? (
            <>
              <ThemeButton
                theme='monkey'
                selected={themeType === 'default'}
                onClick={() => setThemeTypeHandler('default')}
              />
              <ThemeButton
                theme='dracula'
                selected={themeType === 'dracula'}
                onClick={() => setThemeTypeHandler('dracula')}
              />
              <ThemeButton
                theme='synth-wave'
                selected={themeType === 'synth-wave'}
                onClick={() => setThemeTypeHandler('synth-wave')}
              />
            </>
          ) : (
            <>
              <ThemeButton
                theme='solarize'
                selected={themeType === 'default'}
                onClick={() => setThemeTypeHandler('default')}
              />
              <ThemeButton
                theme='horizon'
                selected={themeType === 'horizon'}
                onClick={() => setThemeTypeHandler('horizon')}
              />
              <ThemeButton
                theme='tomorrow'
                selected={themeType === 'tomorrow'}
                onClick={() => setThemeTypeHandler('tomorrow')}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
}
