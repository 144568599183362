import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus } from '@fortawesome/free-solid-svg-icons';
const FontAwesomeMinus = () => {
  return (
    <div>
      <FontAwesomeIcon className='minus-button' icon={faMinus} />
    </div>
  );
};

export default FontAwesomeMinus;
