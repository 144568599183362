import React from 'react';
import MonkeyType from './ThemeButtons/MonkeyType';
import Dracula from './ThemeButtons/Dracula';
import SynthWave from './ThemeButtons/SynthWave';
import Tomorrow from './ThemeButtons/Tomorrow';
import Horizon from './ThemeButtons/Horizon';
import Solarize from './ThemeButtons/Solarize';

export default function ThemeButton({ theme, selected, onClick }) {
  return (
    <>
      <div
        className={`theme-btn btn-${theme} ${selected ? 'selected' : ''}`}
        onClick={onClick}
      >
        <span>
          {theme === 'monkey' ? <MonkeyType /> : null}
          {theme === 'dracula' ? <Dracula /> : null}
          {theme === 'synth-wave' ? <SynthWave /> : null}
          {theme === 'solarize' ? <Solarize /> : null}
          {theme === 'horizon' ? <Horizon /> : null}
          {theme === 'tomorrow' ? <Tomorrow /> : null}
        </span>
      </div>
    </>
  );
}
