import { useEffect } from 'react';

export const useKey = (keyStroke, keyFunction) => {
  useEffect(() => {
    function handleKeystroke(event) {
      let os = navigator?.userAgent;

      if (event?.key === 'Alt' && keyStroke === 'alt') keyFunction();
      // varify keystroke
      else if (event?.key?.toLowerCase() === keyStroke) {
        // For Mac OS cmd key should've been pressed
        if (event?.metaKey && os?.search('Mac') !== -1) {
          keyFunction();
        }
        // For Windows && Linux ctrl key should've been pressed
        else if (
          (os?.search('Windows') !== -1 || os?.search('Linux') !== -1) &&
          event?.ctrlKey
        ) {
          keyFunction();
        }
      }
    }

    document.addEventListener('keydown', handleKeystroke);
    return () => {
      document.removeEventListener('keydown', handleKeystroke);
    };
  }, [keyFunction, keyStroke]);
};
