import React from 'react';
import SunCentre from '../../assets/sun-centre.svg';
import SunRay from '../../assets/sun-ray.svg';

export default function Solarize() {
  return (
    <div className='solarize-svg'>
      <div className='sun-centre'>
        <img
          src={SunCentre}
          alt='sun-centre'
        />
      </div>
      <div className='sun-ray'>
        <img
          src={SunRay}
          alt='sun-ray'
        />
      </div>
    </div>
  );
}
