import React, { useEffect, useState } from 'react';

export default function ShortkeyPopover({ code, shortkey, show }) {
  const [cmd, setCmd] = useState('');
  useEffect(() => {
    let os = navigator.userAgent;
    if (os?.search('Windows') !== -1) {
      setCmd('ctrl');
    } else if (os?.search('Mac') !== -1) {
      setCmd('cmd');
    } else if (os?.search('X11') !== -1 && !(os?.search('Linux') !== -1)) {
      setCmd('ctrl');
    } else if (os?.search('Linux') !== -1 && os?.search('X11') !== -1) {
      setCmd('ctrl');
    }
  }, []);
  return (
    <>
      {show ? (
        <div
          className={`shortkey-popover ${
            shortkey?.toLowerCase() === 'i' ? 'top-x35' : ''
          }`}
        >
          <div className='carrot-sign' />
          <div className='code-name'>{code}</div>
          <di className='code-value'>
            <div>{cmd}</div>
            <div>{shortkey}</div>
          </di>
        </div>
      ) : (
        ''
      )}
    </>
  );
}
