import { useEffect } from 'react';
import { createPortal } from 'react-dom';
import toastImg from '../assets/toast-success.svg';
import CancelIcon from '../components/CancelIcon';

const ShortkeyToast = ({ text, onClose, id }) => {
  useEffect(() => {
    const timeout = setTimeout(onClose, 2000);
    return () => clearTimeout(timeout);
    //eslint-disable-next-line
  }, [text]);
  return (
    <>
      {text
        ? createPortal(
            <div className='toast-container'>
              <div className='img-wrapper'>
                <img
                  src={toastImg}
                  alt='toast-success'
                />
              </div>
              <span style={{ width: '75%' }}>
                Successfully <strong>{text}</strong> JSON input
              </span>
              <div className='toast-btn'>
                <button onClick={onClose}>
                  <CancelIcon />
                </button>
              </div>
            </div>,
            document.getElementById(id)
          )
        : ''}
    </>
  );
};

export default ShortkeyToast;
