import React, { useState } from 'react';
import FontAwesomePlus from './FontAwesomePlus';
import FontAwesomeMinus from './FontAwesomeMinus';
import isEmptyObject from '../utils/ObjectUtils.js';
import checkValueTypeHorizontalView from '../utils/CheckValueTypeHorizontalView';
const CollapseArrayTable = ({ data, showDataType }) => {
  const [collapseTableFlag, setCollapseTableFlag] = useState(true);
  const handleClick = () => setCollapseTableFlag(!collapseTableFlag);
  return (
    <div>
      <div className='button-div'>
        <button className='fontawesome-button ' onClick={handleClick}>
          {collapseTableFlag ? <FontAwesomeMinus /> : <FontAwesomePlus />}
        </button>
      </div>
      {isEmptyObject(data) ? (
        <div className='empty-object'>empty object</div>
      ) : (
        <table
          border='1'
          className={collapseTableFlag ? '' : 'display-table-content'}
        >
          <tbody>
            {' '}
            {data &&
              data?.map((value, index) => (
                <tr key={index}>
                  <td className='index'>
                    {index}{' '}
                    <div className='data-type'>
                      {' '}
                      {`  ${
                        Array?.isArray(value)
                          ? `Array[${value?.length}]`
                          : showDataType
                          ? typeof value
                          : ''
                      }`}{' '}
                    </div>
                  </td>
                  <td>{checkValueTypeHorizontalView(value, showDataType)}</td>
                </tr>
              ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default CollapseArrayTable;
